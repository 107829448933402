import {KeypointsAnalyser} from "./KeypointsAnalyser";

const keypointsAnalyser = new KeypointsAnalyser();
export function removePartCapturedPoses(poses) {
    for (let pose of poses){
        if (!keypointsAnalyser.isFullPersonCaptured(pose.keypoints)){
            poses.splice(poses.indexOf(pose), 1);
        }
    }
    return poses;
}