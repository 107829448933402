export class EnhanceInfluenceWithTime {
    _enhanceScore = 1;
    start;

    get enhanceScore () {
        return this._enhanceScore * this.getElapsedTime() > 0.5 ? 0.5 : this._enhanceScore * this.getElapsedTime();
    }

    restart(){
        this.start = new Date().getTime();
    }

    getElapsedTime (){
        if (!this.start){
            this.restart();
            return 0;
        } else {
            return new Date().getTime() - this.start;
        }
    }



}