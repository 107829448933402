import {ResultSmooth} from "./TimeInfluence/ResultSmooth";
import {DistributorRandomizer} from "./RandomDistribuiton/DistributorRandomizer";
import {PosesTracker} from "./PoseNumberChanges/PosesTracker";

export class PoseMemory {

    static many(number) {
        return Array.from(Array(number).keys()).map(() => new PoseMemory());
    }


    constructor() {
        this.updateKeypoints = this.updateKeypoints.bind(this);
    }

    _currentKeypoints = [];
    _prevKeypoints = [];
    _distributorRandomizer= new DistributorRandomizer();
    resultSmooth = new ResultSmooth();
    poseTracker = new PosesTracker();

    updateKeypoints(newKeypoints) {
        if (newKeypoints.length === 0 || newKeypoints === this._prevKeypoints) {
            return false;
        }
        this._prevKeypoints = newKeypoints;

        this._distributorRandomizer.updateTime();
        const influencePoints = this._distributorRandomizer.wholeInfluenceDistribution();
        this._currentKeypoints = this.placeKeypoints(newKeypoints, influencePoints);
        return true;
    }

    placeKeypoints(newKeypoints, influencePoints) {
        let currentKeypoints = new Array(512).fill(0);
        for (const influencePoint of influencePoints){
            currentKeypoints[influencePoint.allocatedPlace] = newKeypoints.find(keypoint => keypoint.name === influencePoint.name);
        }
        return currentKeypoints;
    }

    handlePoseNumberChange(poses){
        this.poseTracker.updateCurrentPoses(poses);

        if (this.poseTracker.checkIncreasePoses()) {
            this.distributorRandomizer.distributors.length !== poses.length && this._distributorRandomizer.addDistributor();
            this.poseTracker.prevPosesEmpty() && this._distributorRandomizer.unpauseStopwatch();
        }

        if (this.poseTracker.checkReducePoses()) {
            console.log(this.poseTracker.currentPoses);
            if (!this._distributorRandomizer.checkIsOnlyOneDistributor()){
                this._distributorRandomizer.removeDistributor();
            }
            this.poseTracker.currentPosesEmpty() && this._distributorRandomizer.pauseStopwatch();
        }

        //after all checks
        this.poseTracker.updatePrevPoses();
    }


    get currentKeypoints(){
        return this._currentKeypoints;
    }

    get allocatedPlaces(){
        return this._distributorRandomizer.allocatedPlaces;
    }

    get distributorRandomizer(){
        return this._distributorRandomizer;
    }

}
