export class KeypointsAnalyser {
    isFullPersonCaptured(keypoints){
        if(keypoints.some(point => point === undefined)) {
            throw new Error ("Here are undefined instead of points");
        }
        return !keypoints.some(point => point["score"] < 0.2)
    }


    getBusyIndexesNumber(keypoints){
        let count = 0;
        for (let i = 0; i < keypoints.length; i++){
            typeof keypoints[i] === 'object' && count++;
        }
        return count;
    }
}