import {EnhanceInfluenceWithTime} from "./ChnageInfluenceWithTime";
import {floatNumber} from "../debbugingFunctions/floatNumber";

export class ResultSmooth {
    _baseResult = [];
    influenceEnhancer;

    constructor() {
        this.influenceEnhancer = new EnhanceInfluenceWithTime();
    }

    set baseResult(result){
        if (this.isBaseResultInvalid() && this.isResultValid(result)){
            this.redefineBaseResult(result);
            this.restartInfluenceEnhancer();
        }
    }

    isBaseResultInvalid(){
        return this._baseResult.length === 0 || floatNumber(this._baseResult) % 66 !== 0;
    }

    isResultValid(result){
        return floatNumber(result) % 66 === 0;
    }

    restartInfluenceEnhancer(){
        this.influenceEnhancer.restart();
    }

    redefineBaseResult(result){
        this._baseResult = result;
    }

    async makeResultChangesSmoother(newResult){
        const currentResult = [];
        for (let i = 0; i < this._baseResult.length; i++) {
            let difference = newResult[i] - this._baseResult[i];
            difference *= this.influenceEnhancer.enhanceScore;
            currentResult[i] = this._baseResult[i] + difference;
        }
        return currentResult;
    }
}