import {v4 as uuidv4} from 'uuid';
import FPSMetrics from "./Metrics/FPSMetrics";


export class DebounceProcessor {
    constructor(processor, tick = 100) {
        this.processor = processor;
        this.tick = tick
        this.next = Date.now();
    }

    start() {
        return this.processor.start();
    }

    process(input) {
        if (Date.now() < this.next) {
            return Promise.resolve(false);
        }
        this.next = Date.now() + this.tick;
        return this.processor.process(input);
    }
}

export class QueueProcessors {

    constructor(processors) {
        this.processors = processors
        this.freeProcessors = processors
        this.rps = new FPSMetrics('processor');
    }

    start() {
        this.rps.start();
        return Promise.all(this.processors.map(s => s.start()));
    }

    process(input) {
        const processor = this.freeProcessors.shift();
        const freeProcessors = this.freeProcessors;
        if (!processor) {
            return Promise.resolve(false);
        }
        this.rps.frame();
        return new Promise(resolve => processor.process(input).then(function (data) {
            freeProcessors.push(processor);
            resolve(data);
        }));
    }
}

export class RandomSplitProcessor {

    constructor(processors) {
        this.processors = processors
    }

    start() {
        return Promise.all(this.processors.map(s => s.start()));
    }

    process(input) {
        return this.processors[Number.random(0, this.processors.length - 1)].process(input);
    }
}

export class MaxInQueueProcessors {
    processor;
    maxCountInQueue;
    queued = 0;

    constructor(processor, maxCountInQueue) {
        this.processor = processor;
        this.maxCountInQueue = maxCountInQueue;
    }

    start() {
        return this.processor.start();
    }

    process = async (input) => {
        if (this.queued >= this.maxCountInQueue) {
            return Promise.resolve(false);
        }
        this.queued++;
        try {
            return await this.processor.process(input);
        } finally {
            this.queued--;
        }
    }
}

class WrappedWorker {
    callbacks = {};

    constructor() {
        this.worker = new Worker(new URL("./WsProcessorWorker.js", import.meta.url))
        this.worker.onmessage = this.fireCallbacks;
    }

    send = (type, data) => {
        data = data ?? {};
        this.worker.postMessage({type: type, ...data});
    };

    on = (type, callback) => {
        this.callbacks[type] = callback;
    };

    fireCallbacks = event => {
        this.callbacks[event.data.type](event.data.data);
    };
}

export class SocketProcessor {

    constructor(address) {
        this.address = address;
        this.promises = [];
        this.ready = false;
        this.worker = new WrappedWorker();
    }

    start = () => {
        this.worker.send("start", {address: this.address});
        return new Promise(resolve => {
            this.worker.on('open', () => {
                console.log("OPENED");
                this.ready = true;
                resolve();
            });
            this.worker.on('close', async () => {
                console.log("CLOSED");
                this.ready = false;
                await this.start();
                resolve();
            })
            this.worker.on('message', (responseJSON) => {
                try {
                    // console.log(`Resolving request ${responseJSON.responseId}`);
                    if (!responseJSON.responseId) {
                        if (responseJSON.error) {
                            console.log(responseJSON.error);
                        } else {
                            console.log("Response does not contain error message");
                        }
                        return;
                    }
                    if (this.promises[responseJSON.responseId] !== undefined) {
                        if (responseJSON.output) {
                            // console.log(`Resolving request ${responseJSON.responseId}`);
                            this.promises[responseJSON.responseId].resolve(responseJSON.output);
                            this.promises[responseJSON.responseId] = undefined;
                        } else {
                            if (responseJSON.error) {
                                this.promises[responseJSON.responseId].reject(responseJSON.error);
                            } else {
                                this.promises[responseJSON.responseId].reject("Response does not contain error message");
                            }
                        }
                    } else {
                        console.log(`No promise with id ${responseJSON.responseId}`);
                    }
                } catch (e) {
                    console.log(e.message);
                }
                }
            );
        });
    }


    process = (input) => {
        if (this.ready === false) {
            return Promise.resolve(false);
        }
        const requestId = uuidv4();
        return new Promise((resolve, reject) => {
            this.worker.send("send", {request: {requestId: requestId, input: input}});
            this.promises[requestId] = {resolve, reject};
            setTimeout(() => {
                this.promises[requestId] = undefined;
                reject(new Error(`Request ${requestId} timeout`));
            }, 5000)
        });
    }
}
