export function validateRes(res){
    if (res.length > 512){
        return false;
    }

    for (let value of res){
        if (typeof value === 'number' && isNaN(value)){
            return false;
        }
        if (typeof value !== 'number'){
            return false;
        }
    }

    return true;
}