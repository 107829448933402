import {NoisedNeuralNetworkInput} from "./NoisedNeuralNetworkInput";
import {KeypointsToInputTransformation} from "./KeypointsToInputTransformator";

const noisedInput = new NoisedNeuralNetworkInput();
let keypointsToInputTransformation = new KeypointsToInputTransformation();

export async function keypointsToInput(poseMemory, isUpdated) {
    let currentKeypoints = poseMemory.currentKeypoints;
    let res = keypointsToInputTransformation.transform(currentKeypoints);
    res = res.length ? splitInfluence(res, poseMemory.distributorRandomizer.wholeInfluenceDistribution()) : [];

    poseMemory.resultSmooth.baseResult = res;

    if (!isUpdated) {
        res = noisedInput.random(res, poseMemory.allocatedPlaces);
        poseMemory.resultSmooth.redefineBaseResult(res);
        poseMemory.resultSmooth.restartInfluenceEnhancer();
    }

    let result = await poseMemory.resultSmooth.makeResultChangesSmoother(res);
    result = boundariesOnResult(result);
    return result;
}

function boundariesOnResult(result){
    result.map(item => item > 1 ? 0.9999999 : item);
    result.map(item => item < 0 ? 0.0000001 : item);
    return result;
}

function splitInfluence(res, influencePoints){
    for (const influencePoint of influencePoints){
        res[influencePoint.allocatedPlace] *= influencePoint.enhanceScore;
    }
    return res;
}
