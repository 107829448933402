export default class FPSMetrics {
    name;
    frames = 0;

    constructor(name) {
        this.name = name;

    }

    start = () => {
        setInterval(() => {
            console.log(`[merics.${this.name}] ${this.frames} fps`);
            this.frames = 0;
        }, 1000);
    }

    frame = () => {
        this.frames++;
    }

}
