import {Distributor} from "./Distributor";

export class DistributorRandomizer{
    distributors = [];
    allocatedPlaces = [];

    constructor() {
        this.distributors.push(new Distributor(this));
    }

    addDistributor(){
        this.distributors.push(new Distributor(this));
    }

    removeDistributor(){
        console.log("remove distributor")
        const poppedDistributor = this.distributors.pop();
        poppedDistributor.freeAllocatedPlaces();
    }

    updateTime() {
        this.distributors.forEach(distributor => {
            distributor.update();
        })
    }

    wholeInfluenceDistribution(){
        const influencePoints = [];
        this.distributors.forEach(distributor => {
            influencePoints.push(...distributor.influenceDistribution());
        })
        return influencePoints;
    }

    checkIsOnlyOneDistributor () {
        return this.distributors.length === 1;
    }

    /**
     * Pause if only one distribution
     */
    pauseStopwatch(){
        if (this.checkIsOnlyOneDistributor()) {
            this.distributors[0].pauseStopwatch()
        }
    }

    /**
     * Unpause if only one distribution
     */
    unpauseStopwatch() {
        if (this.checkIsOnlyOneDistributor()) {
            this.distributors[0].unpauseStopwatch()
        }
    }
}