import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import ArtGeneratorTester from "./ArtGenerationTester";
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {setBackendAndEnvFlags} from "./Util";
import {STATE} from "./ParamsPoseDetection";
import PoseDetectorTester from "./PoseDetectorTest";
import Prod from "./Prod";
import MultiArt from "./MultiArt";
import Trypilya from "./Trypilya/Trypilya";
import "./Commons/Primitives";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App address='wss://trypilya.artmotion.world'/>,
    },
    {
        path: "/prod",
        element: <Prod address='wss://monochaos.artmotion.world'/>,
    },
    {
        path: "/monochaos",
        element: <Prod address='wss://monochaos.artmotion.world'/>,
    },
    {
        path: "/trypilya",
        element: <Trypilya/>,
    },
    {
        path: "/multi-art",
        element: <MultiArt address='wss://monochaos.artmotion.world'/>
    },
    {
        path: "/test/art-generation",
        element: <ArtGeneratorTester/>
    },
    {
        path: "/test/pose-detection",
        element: <PoseDetectorTester/>
    }
]);

async function start() {
    await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    await ReactDOM.createRoot(document.getElementById("root")).render(
        <React.StrictMode>
            <RouterProvider router={router}/>
        </React.StrictMode>
    );

    reportWebVitals();
}

start().then(_ => console.log("Started"));
