// noinspection JSUnusedLocalSymbols

import './App.css';
import '@tensorflow/tfjs-backend-webgl';
import React from 'react'


import {keypointsToInput} from "./keypointsToInput";
import {validateRes} from "./Validations/NeuralNetworkInputValidation";
import {keypointsOfPoses} from "./keypointsOfPoses";
import {Preparing} from "./Preparing";
import FPSMetrics from "./Metrics/FPSMetrics";

const preparing = new Preparing(renderResult);
const metrics = new FPSMetrics('art');

async function renderResult() {
    if (preparing.camera.video.readyState < 2) {
        await new Promise((resolve) => {
            preparing.camera.video.onloadeddata = () => {
                resolve();
            };
        });
    }

    const poses = await preparing.poseDetector.estimatePoses(preparing.camera.video);
    preparing.poseMemory.handlePoseNumberChange(poses);
    let keypoints = keypointsOfPoses(poses);
    const isUpdated = preparing.poseMemory.updateKeypoints(keypoints, poses); //from array(34) to array(512) with random distribution
    const res = await keypointsToInput(preparing.poseMemory, isUpdated);
    validateRes(res) && preparing.processor.process(res)
        .then(function (data) {
            if (!data) {
                return;
            }
            metrics.frame();
            preparing.result.setAttribute("src", data);
        });

    preparing.camera.drawCtx();
    if (poses && poses.length > 0) {
        await preparing.camera.drawResults(poses);
    }
}

class App extends React.Component {
    started = false;
    state = {
        camera: ''
    };

    async componentDidMount() {
        if (this.started) {
            return;
        }
        this.started = true;
        const searchParams = new URLSearchParams(document.location.search);
        preparing.prepare(searchParams.get('server') ?? this.props.address).then(r => console.log("Prepared"));
        this.setState(() => {
            return {'camera': searchParams.get('camera') ?? ''}
        })
        metrics.start();
        setInterval(renderResult, 40);
    }

    render() {
        return (<div id="main">
                <div className="container">
                    <div style={{display: "flex"}} className={"prod"}>
                        <div className={"abstract-pic-container"}>
                            <img className={"abstract-pic"} alt="abstract-pic" id="result"/>
                            <div className={"camera-container " + this.state.camera}>
                                <div className="canvas-wrapper">
                                    <canvas id="output"></canvas>
                                    <video id="video" playsInline style={{
                                        WebkitTransform: 'scaleX(-1)',
                                        transform: 'scaleX(-1)',
                                        visibility: 'hidden',
                                        width: '0',
                                        height: '0'
                                    }}>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="scatter-gl-container"></div>
                </div>
            </div>);
    }
}

export default App;
