// noinspection JSUnusedLocalSymbols

import './App.css';
import '@tensorflow/tfjs-backend-webgl';
import React from 'react'

import {STATE} from './ParamsPoseDetection';
import {setBackendAndEnvFlags} from './Util';
import {SocketProcessor} from "./WsProcessor";
import {KeypointsToInputTransformation} from "./KeypointsToInputTransformator";

let processor, running = false;

let keypointsToInputTransformation = new KeypointsToInputTransformation();

async function prepare() {
    if (running) {
        return;
    }
    running = true;
    processor = new SocketProcessor('wss://trypilya.artmotion.world');
    await processor.start();
    await setBackendAndEnvFlags(STATE.flags, STATE.backend);
}

class ArtGeneratorTester extends React.Component {
    constructor() {
        super();

        this.state = {
            pose: "",
            res: "",
            input: "Keypoints",
            src: ""
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
    }
    async componentDidMount() {
        await prepare();
    }

    onInputChange = async event => {
        if (this.state.input === "Keypoints") {
            this.setState(prevState => {
                return {...prevState, pose: event.target.value};
            }, async () => {
                // eslint-disable-next-line
                const keypoints = eval(this.state.pose);
                console.log(keypoints);
                const src = await processor.process(keypointsToInputTransformation.transform(keypoints))
                    .then(function (data) {
                        console.log(data);
                        return data ? data : null;
                    });
                this.setState(prevState => {
                    return{
                        ...prevState,
                        src: src
                    }
                })
            });
        } else if (this.state.input === "Result") {
            const parsed = this.parse(event.target.value);
            if (!parsed || !Array.isArray(parsed)) {
                console.log(`Cannot parse or not array: ${event.target.value}`)
                this.setState(prevState => {
                    return {...prevState, res: `Cannot parse or not array: ${event.target.value}`}
                })
                return;
            }
            console.log(parsed);
            this.setState(prevState => {
                return {...prevState, res: parsed}
            })
            const src = await processor.process(parsed)
                .then(function (data) {
                    console.log(data);
                    return data ? data : null;
                });
            src && this.setState(prevState => {
                return {...prevState, src: src}
            });
        } else {
            console.log("Input is not selected neither to be Keypoints nor Result")
        }
    }

    parse(string) {
        try {
            return JSON.parse(string);
        } catch (e) {
            return false;
        }
    }

    onChangeInput(event) {
        this.setState(prevState => {
            return {...prevState, input: event.target.value};
        }, () => console.log(this.state))
    }

    render() {
        return (
            <div id="main">
                <div className="container">
                    <div style={{display: "flex"}}>
                        <div className="keypoints-input" style={{width: "50%"}}>
                            <div style={{display: "flex"}} onChange={this.onChangeInput}>
                                <div style={{width: "50%", boxSizing: "border-box", margin: 0}}>
                                    <input id="keypoints" type="radio" value="Keypoints" name="input"
                                           defaultChecked={this.state.input === "Keypoints"}/>
                                    <label htmlFor="">Keypoints</label></div>
                                <div style={{width: "50%", boxSizing: "border-box"}}>
                                    <input id="result" type="radio" value="Result" name="input"
                                           defaultChecked={this.state.input === "Result"}/>
                                    <label htmlFor="result">Result (NN input)</label>
                                </div>
                            </div>
                            <input type="text" onChange={this.onInputChange}
                                   style={{width: "100%", boxSizing: "border-box"}}/>
                            <code id="result-pose" style={{fontSize: "14px"}}>{this.state.res}</code>
                        </div>
                        <img alt="abstract-pic" id="result" src={this.state.src} style={{
                            width: '50%',
                            height: '50%'
                        }}/>
                    </div>
                    <div id="scatter-gl-container"></div>
                </div>
            </div>
        );
    }

}

export default ArtGeneratorTester;
