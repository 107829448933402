/**
 * help method in debugging
 * @param array array of distributed keypoints in 512 length array where object {} is a keypoint and 0 is empty space
 */
export function objectsNumber(array){
    return array.reduce((acc, element) => {
        if (typeof element === 'object') {
            return acc + 1;
        }
        return acc;
    }, 0);
}