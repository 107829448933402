export class Stopwatch {
    constructor() {
        this.startTime = 0;
        this.elapsedTime = 0;
        this.isRunning = false;
        this.pauseStartTime = 0;
        this.pausedTime = 0;
    }

    start() {
        if (!this.isRunning) {
            this.startTime = new Date().getTime();
            this.isRunning = true;
        }
    }

    restart() {
        this.startTime = new Date().getTime();
        this.elapsedTime = 0;
        this.pauseStartTime = 0;
        this.pausedTime = 0;
    }

    pause() {
        if (this.isRunning) {
            this.pauseStartTime = new Date().getTime();
            this.elapsedTime = new Date().getTime() - this.startTime - this.pausedTime;
            this.isRunning = false;
        } else {
            console.log("Stopwatch is paused");
        }
    }

    unpause() {
        if (!this.isRunning) {
            const pauseEndTime = new Date().getTime();
            const pauseDuration = pauseEndTime - this.pauseStartTime;
            this.pausedTime += pauseDuration;
            this.isRunning = true;
            this.pauseStartTime = 0;
        } else {
            // console.log("Stopwatch is running");
        }
    }

    getElapsedTime() {
        if (this.isRunning) {
            return new Date().getTime() - this.startTime - this.pausedTime;
        } else {
            return this.elapsedTime;
        }
    }
}
