
import './App.css';
import '@tensorflow/tfjs-backend-webgl';
import React from 'react'


import {KeypointsAnalyser} from "./KeypointsAnalyser";
import {validateRes} from "./Validations/NeuralNetworkInputValidation";
import {keypointsToInput} from "./keypointsToInput";
import {DetectedPeopleToPoses} from "./DetectedPeopleToKeypoints/DetectedPeopleToPoses";
import {objectsNumber} from "./debbugingFunctions/objectsNumber";
import {floatNumber} from "./debbugingFunctions/floatNumber";
import {keypointsOfPoses} from "./keypointsOfPoses";
import {removePartCapturedPoses} from "./removePartCapturedPoses";
import {Preparing} from "./Preparing";

const preparing = new Preparing(renderResult);
let keypointsAnalyser = new KeypointsAnalyser();
let waitCaptureFullPerson = false;
let printLogs = false;

function onChangeCapturingProps(event) {
    waitCaptureFullPerson = event.target.value === "Full Person";
}


async function renderResult() {
    if (preparing.camera.video.readyState < 2) {
        await new Promise((resolve) => {
            preparing.camera.video.onloadeddata = () => {
                resolve();
            };
        });
    }

    const detectedPeople = await preparing.peopleDetector.detectPeople(preparing.camera.video);

    const detectedPeopleToPoses = new DetectedPeopleToPoses(preparing.camera.video, detectedPeople, preparing.poseDetector);

    let poses = await detectedPeopleToPoses.transform();

    if (waitCaptureFullPerson){
        poses = removePartCapturedPoses(poses);
    }

    let keypoints = keypointsOfPoses(poses);

    preparing.poseMemory.handlePoseNumberChange(poses);

    const isUpdated = preparing.poseMemory.updateKeypoints(keypoints);  //from array(33) to array(512) with random distribution
    const currentKeypoints = preparing.poseMemory.currentKeypoints;
    if ((keypointsAnalyser.isFullPersonCaptured(currentKeypoints) && waitCaptureFullPerson) || !waitCaptureFullPerson) {
        const res = await keypointsToInput(preparing.poseMemory, isUpdated);

        if (printLogs){
            console.log("Poses:");
            console.log(poses);
            console.log("Input Keypoints:")
            console.log(keypoints);
            console.log("Current Keypoints:")
            console.log(currentKeypoints);
            console.log("Number of keypoints in current Keypoints:");
            console.log(objectsNumber(currentKeypoints));
            console.log("Res:");
            console.log(res);
            console.log("Number of float numbers:");
            console.log(floatNumber(res));
            console.log("Number of busy indexes:");
            console.log(keypointsAnalyser.getBusyIndexesNumber(currentKeypoints));
            console.log("camera.video:");
            console.log(preparing.camera.video);
            console.log("Allocated Places:");
            console.log(preparing.poseMemory.distributorRandomizer.allocatedPlaces);
            printLogs = false;
        }
        validateRes(res) && preparing.processor.process(res)
            .then(function (data) {
                console.log(data);
                return data ? preparing.result.setAttribute("src", data) : null;
            });

    }

    preparing.camera.drawCtx();
    if (poses && poses.length > 0) {
        await preparing.camera.drawResults(poses, detectedPeople);
    }
}

function logsPrint() {
    printLogs = true;
}

class App extends React.Component {
    componentDidMount() {
        preparing.prepare(this.props.address).then(_ => console.log("Prepared"));
    }

    render() {
        return (
                <div id="main">
                    <div className="container">
                        <div style={{display: "flex"}}>
                            <div className="canvas-wrapper">
                                <canvas id="output"></canvas>
                                <video id="video" playsInline style={{
                                    WebkitTransform: 'scaleX(-1)',
                                    transform: 'scaleX(-1)',
                                    visibility: 'hidden',
                                    width: '0',
                                    height: '0'
                                }}>
                                </video>
                                <div style={{display: "flex", margin: "10px 0"}} onChange={onChangeCapturingProps}>
                                    <div style={{width: "50%", boxSizing: "border-box", margin: 0}}>
                                        <input id="fullPerson" type="radio" value="Full Person" name="input" defaultChecked={waitCaptureFullPerson}/>
                                        <label htmlFor="fullPerson">Generate only when full person is captured</label>
                                    </div>
                                    <div style={{width: "50%", boxSizing: "border-box"}}>
                                        <input id="noConstraints" type="radio" value="No Constraints" name="input"  defaultChecked={!waitCaptureFullPerson}/>
                                        <label htmlFor="noConstraints">No Constraints (even if it is a part of body) </label>
                                    </div>
                                </div>
                                <button onClick={logsPrint} >Log Current Situation</button>
                                <div className="pose-estimation-model">
                                    selection
                                </div>
                                <canvas id="test"></canvas>
                            </div>
                            <img alt="abstract-pic" id="result" className={"abstract-pic"}/>
                        </div>
                        <div id="scatter-gl-container"></div>
                    </div>
                </div>
        );
    }
}

export default App;
