export class KeypointsToInputTransformation {

// noinspection JSUnusedLocalSymbols
    transform(transformationInput) {
        transformationInput = transformationInput.map(point =>
            !!point ?
                point.x * 1000 * point.score +
                point.y * 1000 * point.score : 0)
        return transformationInput;
    }

}
