export class DetectedPeopleToPoses{
    video
    detections;
    poseDetector;

    constructor(video, detections, poseDetector) {
        this.video = video;
        this.detections = detections;
        this.poseDetector = poseDetector;
    }

    /**
     * TODO: Contains problems with resolving is content good to be proceeding then
     * items should not be undefined. if it is undefined it should be removed from poses
     */
    async transform() {
        let poses = []
        // console.log(this.detections);
        for (let detectedPerson of this.detections) {
            //get one pose from one detected person
            let pose = await this.poseDetector.estimatePoses(this.detectionToCanvas(detectedPerson))
            pose = pose[0];
            // console.log(pose);
            /* May occur when object detector made a mistake and detected object is not a person,
             * in such case just got to next detected object
             */
            if(pose !== undefined) {
                for (let keypoint of pose.keypoints) {
                    keypoint.x += detectedPerson.boundingBox.originX;
                    keypoint.y += detectedPerson.boundingBox.originY;
                }
                // console.log(pose);
                poses.push(pose)
            }

        }
        poses = poses.filter(pose => pose !== undefined);

        return await new Promise(resolve => {
            resolve(poses);
        })
    }

    detectionToCanvas(detection){
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        // console.log(detection)
        const x = detection.boundingBox.originX;
        const y = detection.boundingBox.originY;
        const width = detection.boundingBox.width;
        const height = detection.boundingBox.height;
        canvas.width = width;
        canvas.height = height;

        // ctx.translate(width, 0);
        // ctx.scale(-1, 1);

        ctx.drawImage(
            this.video, x, y, width, height, 0, 0, width, height);


        return canvas;

        // document.body.appendChild(canvas);
    }

}