import {DebounceProcessor, MaxInQueueProcessors, RandomSplitProcessor, SocketProcessor} from "./WsProcessor";
import {Camera} from "./Camera";
import {STATE} from "./ParamsPoseDetection";
import {setBackendAndEnvFlags} from "./Util";
import {PoseDetector} from "./PoseEstimation/PoseDetector";
import {Detector} from "./ObjectDetection/Detector";
import * as posedetection from "@tensorflow-models/pose-detection";
import {PoseMemory} from "./PoseMemory";


export class Preparing {
    model = posedetection.SupportedModels.BlazePose;
    maxPoses = 1;
    running;
    result;
    processor;
    processors = [];
    camera;
    poseDetector;
    peopleDetector;
    renderResult;
    poseMemory;


    constructor(renderResult) {
        this.renderResult = renderResult;
    }

    renderPrediction = async () => {
        await this.renderResult();
    }


    async prepare(socketProcessorAddresses) {
        if (this.running) {
            return;
        }
        this.running = true;
        this.result = document.getElementById('result');
        this.socket = new RandomSplitProcessor((socketProcessorAddresses).split(',').map(url => new SocketProcessor(url)));
        [...Array(6)].forEach((_) => this.processors.push(new DebounceProcessor(new MaxInQueueProcessors(this.socket, 10), 40)));
        this.processor = this.processors[0];
        await this.processor.start();
        this.camera = await Camera.setupCamera(STATE.camera, this.model);

        await setBackendAndEnvFlags(STATE.flags, STATE.backend);

        this.poseDetector = await PoseDetector.create(this.model, this.maxPoses);
        this.peopleDetector = await Detector.create();
        this.poseMemory = new PoseMemory();
    }

}
