export class PosesTracker {
    prevPoses = [];
    currentPoses = [];

    updateCurrentPoses (poses) {
        this.currentPoses = poses;
    }

    updatePrevPoses(){
        this.prevPoses = this.currentPoses;
    }

    checkIncreasePoses(){
        return this.currentPoses.length > this.prevPoses.length;
    }

    checkReducePoses() {
        return this.currentPoses.length < this.prevPoses.length;
    }

    currentPosesKeepsPoses () {
        return this.currentPoses.length !== 0;
    }

    currentPosesEmpty() {
        return this.currentPoses.length === 0;
    }

    prevPosesEmpty() {
        return this.prevPoses.length === 0;
    }
}