// noinspection JSUnusedLocalSymbols

import './App.css';
import '@tensorflow/tfjs-backend-webgl';
import React from 'react'


import {PoseDetector} from "./PoseEstimation/PoseDetector";
import {KeypointsToInputTransformation} from "./KeypointsToInputTransformator";
import {SocketProcessor} from "./WsProcessor";
import {setBackendAndEnvFlags} from "./Util";
import {STATE} from "./ParamsPoseDetection";

let detector;
let keypointsToInputTransformation = new KeypointsToInputTransformation();
let processor, running = false;
let poses, pose;

async function generateArt(image) {
    if (pose === undefined) {
        console.log("Firstly Choose image");
    } else {
        const generatorInput = keypointsToInputTransformation.transform(pose);

        console.log(generatorInput);

        const artPicSrc = await processor.process(generatorInput)
            .then(function (data) {
                // console.log(data)
                return data ? data : null;
            });

        return {pose: JSON.stringify(pose), generatorInput: JSON.stringify(generatorInput), artPicture: artPicSrc};
    }

}


async function prepare() {
    if (running) {
        return;
    }
    running = true;
    processor = new SocketProcessor('wss://fame.artmotion.world');
    await processor.start();
    await setBackendAndEnvFlags(STATE.flags, STATE.backend);

    detector = await PoseDetector.create();
}


class PoseDetectorTester extends React.Component {

    state = {
        pose: "",
        generatorInput: "",
        src: "",
        artPicture: "",
        img: undefined
    }
    async componentDidMount() {
        await prepare();
    }

    onImageChange = async (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];

        const result = await new Promise(resolve => {
            reader.addEventListener('load', () => {
                resolve(reader.result);
            });
            if (file) {
                reader.readAsDataURL(file);
            }
        })

        const img = new Image(800, 800);
        img.src = result;

        await this.setState({img: img}, async ()=> {
            console.log("Waiting for getting input image");
            poses = await detector.estimatePoses(this.state.img);

            pose = poses[0] || [];
            pose = (pose && pose.keypoints) || [];

            console.log(pose);

            this.setState(await generateArt(this.state.img));
            console.log("Done");
        });
    };

    render() {
        return (
            <div id="main" className="canvas-wrapper" style={{display: "flex"}}>
                    <div>
                        <div className={"two-column-layout"} style={{height: "300px", width: "900px"}}>
                            <input type="file" multiple accept="image/*" onChange={this.onImageChange} className={"column"}/>
                        </div>
                        <div>

                            <label htmlFor={"generated-abstract-picture"} style={{fontSize: "20px",
                                display: this.state.artPicture ? "block" : "none",
                                textAlign: "center",
                                margin: "40px 0 20px 0"
                            }}>Generated Art</label>

                            <img src={this.state.artPicture} alt="abstract-pic" style={{display: this.state.artPicture ? "block" : "none",
                                                                                        width: "800px",
                                                                                        height: "800px",
                                                                                        margin: "0 auto"
                                                                                        }}/>

                        </div>
                    </div>
                    <div style={{width: "900px"}}>

                        <label htmlFor={"result-pose"} style={{fontSize: "20px",
                            display: this.state.pose ? "block" : "none",
                            textAlign: "center",
                            margin: "40px 0 20px 0"
                        }}>Pose Keypoints</label>
                        <code id="result-pose" style={{fontSize: "14px"}}>{this.state.pose}</code>

                        <label htmlFor={"result-generation-input"} style={{fontSize: "20px",
                            display: this.state.pose ? "block" : "none",
                            textAlign: "center",
                            margin: "40px 0 20px 0"
                        }}>Generation Input</label>
                        <code id="result-generator-input">{this.state.generatorInput}</code>

                    </div>
            </div>
        );
    }
}

export default PoseDetectorTester;
