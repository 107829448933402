import * as posedetection from '@tensorflow-models/pose-detection';

import * as params from './ParamsPoseDetection';

import {isMobile} from './Util';

// These anchor points allow the pose pointcloud to resize according to its
// position in the input.

// #ffffff - White
// #800000 - Maroon
// #469990 - Malachite
// #e6194b - Crimson
// #42d4f4 - Picton Blue
// #fabed4 - Cupid
// #aaffc3 - Mint Green
// #9a6324 - Kumera
// #000075 - Navy Blue
// #f58231 - Jaffa
// #4363d8 - Royal Blue
// #ffd8b1 - Caramel
// #dcbeff - Mauve
// #808000 - Olive
// #ffe119 - Candlelight
// #911eb4 - Seance
// #bfef45 - Inchworm
// #f032e6 - Razzle Dazzle Rose
// #3cb44b - Chateau Green
// #a9a9a9 - Silver Chalice
const COLOR_PALETTE = [
    '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4', '#aaffc3',
    '#9a6324', '#000075', '#f58231', '#4363d8', '#ffd8b1', '#dcbeff', '#808000',
    '#ffe119', '#911eb4', '#bfef45', '#f032e6', '#3cb44b', '#a9a9a9'
];

export class Camera {
    model;
    video = document.getElementById('video');
    canvas = document.getElementById('output');
    ctx = this.canvas.getContext('2d');
    constructor(model) {
        this.model = model;
    }

    /**
     * Initiate a CameraPosesNet instance and wait for the camera stream to be ready.
     * @param cameraParam From app `STATE.camera`.
     * @param model pose detection model: BlazePose or PoseNet
     */
    static async setupCamera(cameraParam, model) {
        const stream = await this.stream(cameraParam);
        const camera = new Camera(model);
        camera.video.srcObject = stream;

        await new Promise((resolve) => {
            camera.video.onloadedmetadata = () => {
                resolve();
            };
        });

        camera.video.play();
        const videoWidth = camera.video.videoWidth;
        const videoHeight = camera.video.videoHeight;
        // Must set below two lines, otherwise video element doesn't show.
        camera.video.width = videoWidth;
        camera.video.height = videoHeight;

        camera.canvas.width = videoWidth;
        camera.canvas.height = videoHeight;

        // Because the image from camera is mirrored, need to flip horizontally.
        camera.ctx.translate(camera.video.videoWidth, 0);
        camera.ctx.scale(-1, 1);
        return camera;
    }

    static async stream(cameraParam) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            throw new Error(
                'Browser API navigator.mediaDevices.getUserMedia not available');
        }

        const {targetFPS, sizeOption} = cameraParam;
        const $size = params.VIDEO_SIZE[sizeOption];
        const videoConfig = {
            'audio': false,
            'video': {
                facingMode: 'user',
                // Only setting the video to a specified size for large screen, on
                // mobile devices accept the default size.
                width: isMobile() ? params.VIDEO_SIZE['360 X 270'].width : $size.width,
                height: isMobile() ? params.VIDEO_SIZE['360 X 270'].height :
                    $size.height,
                frameRate: {
                    ideal: targetFPS,
                }
            }
        };

        return await navigator.mediaDevices.getUserMedia(videoConfig);
    }

    drawCtx() {
        this.ctx.drawImage(
            this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);
    }

    async drawResults(poses, detectedPeople = []) {
        for (const pose of poses) {
            pose.score > 0.2 && (await this.drawResult(pose));
        }
        for (const detectedPerson of detectedPeople) {
            // console.log(detectedPerson)
            await this.drawDetection(detectedPerson);
        }
    }

    drawDetection(detectedPerson){
        // console.log("Offset Width")
        // console.log(this.video.offsetWidth);
        // console.log("Video Width")
        // console.log(this.video.width);
        // console.log("Detected Person")
        // console.log(detectedPerson);
        const x =
            detectedPerson.boundingBox.originX;
        const y = detectedPerson.boundingBox.originY;
        const width = detectedPerson.boundingBox.width - 10;
        const height = detectedPerson.boundingBox.height;

        // console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`);

        this.ctx.fillStyle = 'rgba(0, 255, 0, 0.25)';
        this.ctx.fillRect(x, y, width, height);
        this.ctx.save();

    }

    async drawResult(pose) {
        if (pose.keypoints != null) {
            this.drawKeypoints(pose.keypoints);
            this.drawSkeleton(pose.keypoints, pose.id);
        }
    }

    /**
     * Draw the keypoints on the video.
     * @param keypoints A list of keypoints.
     */
    drawKeypoints(keypoints) {
        const keypointInd =
            posedetection.util.getKeypointIndexBySide(this.model);
        this.ctx.fillStyle = 'Red';
        this.ctx.strokeStyle = 'White';
        this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

        for (const i of keypointInd.middle) {
            this.drawKeypoint(keypoints[i]);
        }

        this.ctx.fillStyle = 'Green';
        for (const i of keypointInd.left) {
            this.drawKeypoint(keypoints[i]);
        }

        this.ctx.fillStyle = 'Orange';
        for (const i of keypointInd.right) {
            this.drawKeypoint(keypoints[i]);
        }
    }

    drawKeypoint(keypoint) {
        // If score is null, just show the keypoint.
        const score = keypoint.score != null ? keypoint.score : 1;
        const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

        if (score >= scoreThreshold) {
            const circle = new Path2D();
            circle.arc(keypoint.x, keypoint.y, params.DEFAULT_RADIUS, 0, 2 * Math.PI);
            this.ctx.fill(circle);
            this.ctx.stroke(circle);
        }
    }

    /**
     * Draw the skeleton of a body on the video.
     * @param keypoints A list of keypoints.
     * @param poseId
     */
    drawSkeleton(keypoints, poseId) {
        // Each poseId is mapped to a color in the color palette.
        const color = params.STATE.modelConfig.enableTracking && poseId != null ?
            COLOR_PALETTE[poseId % 20] :
            'White';
        this.ctx.fillStyle = color;
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

        posedetection.util.getAdjacentPairs(this.model).forEach(([
                                                                             i, j
                                                                         ]) => {
            const kp1 = keypoints[i];
            const kp2 = keypoints[j];

            // If score is null, just show the keypoint.
            const score1 = kp1.score != null ? kp1.score : 1;
            const score2 = kp2.score != null ? kp2.score : 1;
            const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

            if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
                this.ctx.beginPath();
                this.ctx.moveTo(kp1.x, kp1.y);
                this.ctx.lineTo(kp2.x, kp2.y);
                this.ctx.stroke();
            }
        });
    }
}
