
import './App.css';
import '@tensorflow/tfjs-backend-webgl';
import React from 'react'


import {validateRes} from "./Validations/NeuralNetworkInputValidation";
import {keypointsToInput} from "./keypointsToInput";
import {DetectedPeopleToPoses} from "./DetectedPeopleToKeypoints/DetectedPeopleToPoses";
import {Preparing} from "./Preparing";
import {PoseMemory} from "./PoseMemory";

const preparing = new Preparing(renderResult);
const poseMemories = PoseMemory.many(4);
let sharedKeypoints = [];

async function renderResult() {
    if (preparing.camera.video.readyState < 2) {
        await new Promise((resolve) => {
            preparing.camera.video.onloadeddata = () => {
                resolve();
            };
        });

    }

    const detectedPeople = await preparing.peopleDetector.detectPeople(preparing.camera.video);
    const detectedPeopleToPoses = new DetectedPeopleToPoses(preparing.camera.video, detectedPeople, preparing.poseDetector);
    const poses = await detectedPeopleToPoses.transform();

    await drawArts(poses);
}

async function drawArts(poses) {

    for (const index of Array.from(Array(poseMemories.length).keys())) {
        const pose = poses[index];
        const wrappedPose = pose === undefined ? [] : [pose];
        let keypoints = pose !== undefined ? pose.keypoints : [];
        sharedKeypoints = sharedKeypoints.length === 0 ? keypoints : sharedKeypoints;
        keypoints.length === 0 && (keypoints = sharedKeypoints);
        const poseMemory = poseMemories[index];
        const result = document.getElementById(`result-${index + 1}`);

        poseMemory.handlePoseNumberChange(wrappedPose);
        let isUpdated = false;
        console.log("Index: " + index)
        isUpdated = poseMemory.updateKeypoints(keypoints);
        console.log(isUpdated)
        const res = await keypointsToInput(poseMemory, isUpdated);
        validateRes(res) && preparing.processors[index].process(res)
            .then(function (data) {
                return data ? result.setAttribute("src", data) : null;
            });

    }

}

class App extends React.Component {
    componentDidMount() {
        preparing.prepare(this.props.address).then(_ => console.log("Prepared"));
    }

    render() {
        return (
            <div id="main">
                <div className="container">
                    <div style={{display: "flex"}} className={"multi"}>
                        <div className="arts">
                            <div className={"abstract-pic-container"}>
                                <img alt="abstract-pic" className={"abstract-pic"} id="result-1"/>
                            </div>
                            <div className={"abstract-pic-container"}>
                                <img alt="abstract-pic" className={"abstract-pic"} id="result-2"/>
                            </div>
                            <div className={"abstract-pic-container"}>
                                <img alt="abstract-pic" className={"abstract-pic"} id="result-3"/>
                            </div>
                            <div className={"abstract-pic-container"}>
                                <img alt="abstract-pic" className={"abstract-pic"} id="result-4"/>
                            </div>
                        </div>
                        <div className={"camera-container"} style={{width: "10px"}}>
                            <div className="canvas-wrapper">
                                <canvas id="output"></canvas>
                                <video id="video" playsInline style={{
                                    WebkitTransform: 'scaleX(-1)',
                                    transform: 'scaleX(-1)',
                                    visibility: 'hidden',
                                    width: '0',
                                    height: '0'
                                }}>
                                </video>
                            </div>
                        </div>
                    </div>
                    <div id="scatter-gl-container"></div>
                </div>
            </div>
        );
    }
}

export default App;
