
// Distributed Keypoints is an array (512) at the moment it has 34 * poseNum * 2 (it has two iterations)
// Influence changes with time from first iteration to second in 30 seconds. each 30 seconds previous iteration
// has been deleted and second become first with creation next iteration
import {InfluenceEnhancer} from "../TimeInfluence/InfluenceEnhancer";
import {InfluencePoint} from "./InfluencePoint";
import {keypointsNames} from "../keypointsNames";
import {Stopwatch} from "../Stopwatch";

export class Distributor {

    _distributorRandomizer;
    _distributionA;
    _distributionB;
    influenceEnhancer;

    constructor(randomDistributor) {
        this._distributorRandomizer = randomDistributor;
        this.influenceEnhancer = new InfluenceEnhancer(20000);
        this._distributionA = this.distributeKeypoints();
        this._distributionB = this.distributeKeypoints();
        this._stopwatch = new Stopwatch();
        this._stopwatch.start();
    }

    distributeKeypoints(){
        const distribution = [];
        for (let name of [...keypointsNames, ...keypointsNames, ...keypointsNames, ...keypointsNames]) {
            distribution.push({
                index: this.randomUnallocatedIndex,
                name: name,
            });
        }
        return distribution;
    }

    redistributeKeypoints(){
        this.freeADistributionAllocatedPlaces();
        this._distributionA = this._distributionB;
        this._distributionB = this.distributeKeypoints();
    }

    freeADistributionAllocatedPlaces(){
        for (const distributedInf of this._distributionA){
            const index = distributedInf.index;
            this._distributorRandomizer.allocatedPlaces.splice(this._distributorRandomizer.allocatedPlaces.indexOf(index), 1);
        }
    }

    freeBDistributionAllocatedPlaces() {
        for (const distributedInf of this._distributionB){
            const index = distributedInf.index;
            this._distributorRandomizer.allocatedPlaces.splice(this._distributorRandomizer.allocatedPlaces.indexOf(index), 1);
        }
    }

    freeAllocatedPlaces(){
        this.freeADistributionAllocatedPlaces();
        this.freeBDistributionAllocatedPlaces();
    }

    get randomUnallocatedIndex() {
        let randomIndex = Math.floor(Math.random() * 512);

        // eslint-disable-next-line
        while (this._distributorRandomizer.allocatedPlaces.some(index => index === randomIndex)) {
            randomIndex = Math.floor(Math.random() * 512);
        }

        this._distributorRandomizer.allocatedPlaces.push(randomIndex);
        return randomIndex;
    }

    get allocatedPlaces() {
        return this._distributorRandomizer.allocatedPlaces;
    }

    update() {
        let elapsedTime = this._stopwatch.getElapsedTime();
        if (elapsedTime > 30000) {
            this._stopwatch.restart();
            console.log("redistribution");
            this.redistributeKeypoints()
            elapsedTime = this._stopwatch.getElapsedTime();
        }
        this.influenceEnhancer.updateTime(elapsedTime);
    }

    pauseStopwatch(){
        this._stopwatch.pause();
    }

    unpauseStopwatch() {
        this._stopwatch.unpause();
    }


    influenceDistribution(){
        const influencePoints = this.getInfluencePoints(this._distributionA, this.influenceEnhancer.firstPhaseScore);
        influencePoints.push(...this.getInfluencePoints(this._distributionB, this.influenceEnhancer.secondPhaseScore));
        return influencePoints;
    }

    getInfluencePoints (distribution, enhanceScore){
        const distributionInfluencePoints = [];
        for (const keypointInf of distribution) {
            const influencePoint = new InfluencePoint();
            influencePoint.name = keypointInf.name;
            influencePoint.allocatedPlace = keypointInf.index;
            influencePoint.enhanceScore = enhanceScore;

            distributionInfluencePoints.push(influencePoint);
        }
        return distributionInfluencePoints;
    }

}
