import React, {Fragment} from "react";
import './TrypilyaArt.css';
import '@tensorflow/tfjs-backend-webgl';
import {keypointsToInput} from "../keypointsToInput";
import {validateRes} from "../Validations/NeuralNetworkInputValidation";
import {PoseMemory} from "../PoseMemory";
import FPSMetrics from "../Metrics/FPSMetrics";

class TrypilyaArt extends React.Component {

    constructor(props) {
        super(props);
        this.metrics = new FPSMetrics('art-' + this.props.id);
        this.renderArt = this.renderArt.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount = async () => {
        if (this.running) {
            return;
        }
        this.running = true;
        this.poseMemory = new PoseMemory();
        this.processor = this.props.processor;
        await this.processor.start();
        this.metrics.start();
        this.art = document.getElementById(this.props.id);
        this.art = this.art.transferControlToOffscreen();
        this.worker = new Worker(new URL("../ImageRender/CanvasWorker.js", import.meta.url));
        this.worker.postMessage({canvas: this.art}, [this.art]);
        setInterval(this.renderArt, 40);
    }

    renderArt = async () => {
        const pose = this.props.pose;

        const wrappedPose = pose === undefined ? [] : [pose];

        let keypoints = pose !== undefined ? pose.keypoints : [];

        this.poseMemory.handlePoseNumberChange(wrappedPose);
        const isUpdated = this.poseMemory.updateKeypoints(keypoints);
        const res = await keypointsToInput(this.poseMemory, isUpdated);
        validateRes(res) && this.processor.process(res)
            .then(data => {
                if (!data) {
                    return false;
                }
                this.metrics.frame();
                this.worker.postMessage({data: data});
            });
    }

    render() {
        return (
            <Fragment>
                <canvas width={1024} height={1024} className={`art art-${this.props.id}`} id={this.props.id}/>
                <div className="art-1-frame"></div>
            </Fragment>
        );
    }
}

export default TrypilyaArt;
