import {FilesetResolver, ObjectDetector} from "@mediapipe/tasks-vision";

export class Detector{

    objectDetector;

    static async create() {
        const humanDetector = new Detector();
        await humanDetector.init();
        return humanDetector;
    }


    // Initialize the object detector
    async init () {
        const vision = await FilesetResolver.forVisionTasks(
            "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
        );
        this.objectDetector = await ObjectDetector.createFromOptions(vision, {
            baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/object_detector/efficientdet_lite0/float16/1/efficientdet_lite0.tflite`,
                delegate: "GPU"
            },
            scoreThreshold: 0.5,
            runningMode: "VIDEO"
        });
    };

    async detectObjects(video) {
        let nowInMs = Date.now();

        return await this.objectDetector.detectForVideo(video, nowInMs);
    }

    async detectPeople(video){
        const detectedObjects = await this.detectObjects(video);

        return detectedObjects.detections.filter(detectedObject => detectedObject.categories.some(category => category.categoryName === "person"));
    }

}
