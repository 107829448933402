import * as tf from '@tensorflow/tfjs-core';

export function isiOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
    return isAndroid() || isiOS();
}

async function resetBackend(backendName) {
    const ENGINE = tf.engine();

    if (backendName in ENGINE.registry) {
        const backendFactory = tf.findBackendFactory(backendName);
        tf.removeBackend(backendName);
        tf.registerBackend(backendName, backendFactory);
    }
    await tf.setBackend(backendName);
}

export async function setBackendAndEnvFlags(flagConfig, backend) {
    if (flagConfig == null) {
        return;
    } else if (typeof flagConfig !== 'object') {
        throw new Error(
            `An object is expected, while a(n) ${typeof flagConfig} is found.`);
    }

    tf.env().setFlags(flagConfig);

    await resetBackend(backend.split('-')[1]);
}