import React from "react";
import './Trypilya.css';
import '@tensorflow/tfjs-backend-webgl';
import {NoisedNeuralNetworkInput} from "../NoisedNeuralNetworkInput";
import TrypilyaArt from "./TrypilyaArt";
import {
    MaxInQueueProcessors,
    RandomSplitProcessor,
    SocketProcessor
} from "../WsProcessor";
import {Camera} from "../Camera";
import {STATE} from "../ParamsPoseDetection";
import {setBackendAndEnvFlags} from "../Util";
import {PoseDetector} from "../PoseEstimation/PoseDetector";
import {Detector} from "../ObjectDetection/Detector";
import {PoseMemory} from "../PoseMemory";
import * as posedetection from "@tensorflow-models/pose-detection";
import FPSMetrics from "../Metrics/FPSMetrics";

class Trypilya extends React.Component {
    static randoms = 15;
    processors = [];
    fps;
    state = {
        arts: [],
        camera: ''
    };

    constructor(props) {
        super(props);
        this.fps = new FPSMetrics(`background`);
        let input = 512;
        this.input = [...Array(input).keys()].map(() => 0.5);
        this.places = [...Array(input).keys()].map((i) => i);
        this.randomizePlaces();
        this.randomizer = new NoisedNeuralNetworkInput();
    }

    randomizePlaces() {
        this.randomPlaces = this.places.randoms(Trypilya.randoms);
    }

    componentDidMount = async () => {
        const searchParams = new URLSearchParams(document.location.search);
        if (this.running) {
            return;
        }
        this.running = true;
        setInterval(this.randomPlaces, 10000);
        this.background = document.getElementById('background');
        this.processor = new MaxInQueueProcessors(new RandomSplitProcessor((searchParams.get('b-server') ?? "wss://trypilya-background.artmotion.world").split(',').map(url => new SocketProcessor(url))), 10);
        await this.processor.start();
        this.socket = new RandomSplitProcessor((searchParams.get('server') ?? "wss://trypilya.artmotion.world").split(',').map(url => new SocketProcessor(url)));
        [...Array(6)].forEach((_) => this.processors.push(new MaxInQueueProcessors(this.socket, 10)));
        this.camera = await Camera.setupCamera(STATE.camera, this.model);
        await setBackendAndEnvFlags(STATE.flags, STATE.backend);
        this.poseDetector = await PoseDetector.create(posedetection.SupportedModels.PoseNet, 5);
        this.peopleDetector = await Detector.create();
        this.poseMemory = new PoseMemory();
        this.fps.start();
        this.setState(() => {
            return {'camera': searchParams.get('camera') ?? ''}
        })
        await this.renderPrediction();
        setInterval(this.renderBackground, 40);
        setInterval(this.renderPrediction, 40);
    }

    renderPrediction = async () => {
        await this.renderTrypilya();
    }

    renderBackground = async () => {
        this.input = this.randomizer.random(this.input, this.randomPlaces, 0.2)
        try {
            const data = await this.processor.process(this.input);
            if (!data) {
                return;
            }
            this.background.setAttribute("src", data);
            this.fps.frame();
        } catch (e) {
            console.log(e.message);
        }
    }

    renderTrypilya = async () => {
        if (this.camera.video.readyState < 2) {
            await new Promise((resolve) => {
                this.camera.video.onloadeddata = () => {
                    resolve();
                };
            });
        }
        // let poses = await this.poseDetector.estimatePoses(this.camera.video);
        // this.updateArts(poses);
        // this.camera.drawCtx();
        // if (poses && poses.length > 0) {
        //     await this.camera.drawResults(poses);
        // }
    }

    updateArts(poses){
        if (poses.length > this.state.arts.length){
            this.addArt(this.state.arts.length + 1, poses[poses.length-1]);
        }
        if (poses.length === this.state.arts.length){
            let newArt = [];
            this.state.arts.forEach(art => {
                newArt.push({id: art.id, pose: poses[art.id-1], processor: art.processor});
            })
            this.setState(_ => ({
                arts: newArt
            }));
        }
    }

    addArt(id, pose) {
        this.setState( state => ({
            arts: [...state.arts, {id: id, pose: pose, processor: this.processors[id-1]}]
        }))
    }

    render() {
        return (<div id="main">
            <canvas id="output"></canvas>
            <video id="video" playsInline style={{
                WebkitTransform: 'scaleX(-1)',
                transform: 'scaleX(-1)',
                visibility: 'hidden',
                width: '0',
                height: '0'
            }}>
            </video>
            <div className="container">
                <div style={{display: "flex"}} className={"prod trypilya"}>
                    <img alt="background" id="background"/>
                    <div className="arts-container">
                        {this.state.arts.map(artData => {
                            return <TrypilyaArt key={artData.id} id={artData.id} pose={artData.pose} processor={artData.processor}/>
                        })}
                    </div>
                    <div className={"camera-container " + this.state.camera}>
                        <div className="canvas-wrapper">
                            <canvas id="output"></canvas>
                        </div>
                    </div>
                </div>
                <div id="scatter-gl-container"></div>
            </div>
        </div>);
    }
}

export default Trypilya;
