export class InfluencePoint {
    _name;
    _allocatedPlace;
    _enhanceScore;

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get allocatedPlace() {
        return this._allocatedPlace;
    }

    set allocatedPlace(value) {
        this._allocatedPlace = value;
    }

    get enhanceScore() {
        return this._enhanceScore;
    }

    set enhanceScore(value) {
        this._enhanceScore = value;
    }
}