import {STATE} from "../ParamsPoseDetection";
import * as posedetection from "@tensorflow-models/pose-detection";

export class PoseDetector {
    detector;
    maxPoses;


    constructor(maxPoses) {
        this.maxPoses = maxPoses;
    }

    /**
     *
     * @param model posedetection.SupportedModels.BlazePose | PoseNet
     * @param maxPoses
     * @returns {Promise<PoseDetector>}
     */
    static async create(model, maxPoses) {
        const detector = new PoseDetector(maxPoses);
        await detector.init(model);
        return detector;
    }

    async init(model) {
        const runtime = STATE.backend.split('-')[0];
        this.detector = await posedetection.createDetector(
            model, {runtime, modelType: STATE.modelConfig.type});
    }

    estimatePoses = async (image) => {
        return await this.detector.estimatePoses(image, {maxPoses: this.maxPoses, flipHorizontal: false});
    }
}
