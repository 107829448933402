export class NoisedNeuralNetworkInput {


    /**
     * Return randomize slightly different result
     * @param {Array} result - the array of transformed captured by camera points of body parts
     * @param allocatedPlaces - the array of allocatedPlaces
     * @param power - power of randomness
     */
    random(result, allocatedPlaces, power = 0.01) {
        const randomDiff = () => (Math.random() * 2 - 1) * power;
        return result.map((value, index) => {
            return allocatedPlaces.some(allocatedPlace => allocatedPlace === index) ? value + randomDiff() : value;
        });
    }
}
