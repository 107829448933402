export class InfluenceEnhancer{
    _time;
    _timeMark

    /**
     *
     * @param timeMark time in seconds after which previous iteration will be deleted and new one will be inserted
     */
    constructor(timeMark) {
        this._timeMark = timeMark;
    }

    updateTime (time){
        this._time = time;
    }
    get firstPhaseScore () {
        return 1 - this.secondPhaseScore;
    }

    get secondPhaseScore () {
        return this._time / this._timeMark;
    }
}